<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="借据编号">
          <el-input
            v-model="searchForm.number"
            clearable
            placeholder="请输入借据编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="资金方">
          <el-select
            ref="searchFormUnitNo"
            v-model="searchForm.unitNo"
            clearable
            placeholder="请选择资金方"
          >
            <el-option
              v-for="opt in agentList"
              :key="opt.unitNo"
              :value="opt.unitNo"
              :label="opt.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="number" fixed min-width="200" label="借据编号">
        </el-table-column>
        <el-table-column prop="unitName" fixed min-width="150" label="资金方">
        </el-table-column>
        <el-table-column prop="name" label="借据名称" width="130"/>
        <el-table-column prop="identifier" label="资金方标识" width="130"/>
        <el-table-column prop="payAmount" label="还款总金额">
           <template v-if="row.payAmount" slot-scope="{row}">
            {{row.payAmount}}元
          </template>
        </el-table-column>
        <el-table-column prop="payIssue" label="还款期数"></el-table-column>
        <el-table-column prop="singleAmount" label="每期还款额" min-width="150">
          <template v-if="row.singleAmount" slot-scope="{row}">
            {{row.singleAmount}}元
          </template>
        </el-table-column>
        <el-table-column prop="payFirstTime" label="首次还款日" min-width="150">
          <template v-if="row.payFirstTime" slot-scope="{row}">
            {{row.payFirstTime.replace(/00:00:00/g,'')}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getFundingPartyList, delFundingParty } from "@/api/fundingParty";
import { queryHaiLiAgent } from "@/api/common";

export default {
  data() {
    return {
      searchForm: {
        number: "",
        unitNo: ""
      },
      agentList: [], //资金方数据
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormUnitNo.blur()
        _this.onSearch()
      }
    };
    this.getFundingPartyList();
    this.queryHaiLiAgent();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addDebt' ) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getFundingPartyList();
    this.queryHaiLiAgent();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getFundingPartyList();
    },
    async getFundingPartyList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const res = await getFundingPartyList(params);
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /*获取资金方列表 */
    async queryHaiLiAgent() {
      const params = {
        role: 0
      };
      const res = await queryHaiLiAgent(params);
      this.agentList = res.ldata;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getFundingPartyList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            number: row.number
          };
          await delFundingParty(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getFundingPartyList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrEditLoanReceipt' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addDebt");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addDebt");
      })
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrEditLoanReceipt' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addDebt",
            query: {
              number: row.number,
              type: "edit"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addDebt",
          query: {
            number: row.number,
            type: "edit"
          }
        });
      })

    }
  },
  components: {}
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
