var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library"},[_c('div',{staticClass:"title"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.searchForm}},[_c('el-form-item',{attrs:{"label":"借据编号"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入借据编号"},model:{value:(_vm.searchForm.number),callback:function ($$v) {_vm.$set(_vm.searchForm, "number", $$v)},expression:"searchForm.number"}})],1),_c('el-form-item',{attrs:{"label":"资金方"}},[_c('el-select',{ref:"searchFormUnitNo",attrs:{"clearable":"","placeholder":"请选择资金方"},model:{value:(_vm.searchForm.unitNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "unitNo", $$v)},expression:"searchForm.unitNo"}},_vm._l((_vm.agentList),function(opt){return _c('el-option',{key:opt.unitNo,attrs:{"value":opt.unitNo,"label":opt.name}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goAdd}},[_vm._v("新增")])],1)],1)],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"number","fixed":"","min-width":"200","label":"借据编号"}}),_c('el-table-column',{attrs:{"prop":"unitName","fixed":"","min-width":"150","label":"资金方"}}),_c('el-table-column',{attrs:{"prop":"name","label":"借据名称","width":"130"}}),_c('el-table-column',{attrs:{"prop":"identifier","label":"资金方标识","width":"130"}}),_c('el-table-column',{attrs:{"prop":"payAmount","label":"还款总金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.payAmount)?[_vm._v(" "+_vm._s(row.payAmount)+"元 ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"payIssue","label":"还款期数"}}),_c('el-table-column',{attrs:{"prop":"singleAmount","label":"每期还款额","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.singleAmount)?[_vm._v(" "+_vm._s(row.singleAmount)+"元 ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"payFirstTime","label":"首次还款日","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.payFirstTime)?[_vm._v(" "+_vm._s(row.payFirstTime.replace(/00:00:00/g,''))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"fixed":"right","width":"200","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"fr mt20"},[_c('el-pagination',{attrs:{"current-page":_vm.beginIndex,"page-size":_vm.pageSize,"page-sizes":[10, 20, 30, 50, 100, 150, 200],"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.beginIndex=$event},"update:current-page":function($event){_vm.beginIndex=$event},"size-change":_vm.handleSizeChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }