<template>
  <el-form
    label-position="top"
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="150px"
  >
    <el-form-item label="资金方" prop="unitNo">
      <el-select
        v-model="form.unitNo"
        clearable
        filterable
        placeholder="请选择资金方"
        style="width:300px"
      >
        <el-option
          v-for="opt in agentList"
          :key="opt.unitNo"
          :value="opt.unitNo"
          :label="opt.name"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="借据名称" prop="name">
      <el-input
        v-model="form.name"
        style="width: 300px"
        placeholder="请输入借据名称"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="借款总金额" prop="loanAmount">
      <el-input
        v-model="form.loanAmount"
        style="width: 300px"
        placeholder="请输入借款总金额"
      ></el-input>
    </el-form-item>
    <el-form-item label="还款总金额" prop="payAmount">
      <el-input
        v-model="form.payAmount"
        style="width: 300px"
        placeholder="请输入还款总金额"
      ></el-input>
    </el-form-item>
    <el-form-item label="还款期数" prop="payIssue">
      <el-input
        v-model="form.payIssue"
        style="width: 300px"
        placeholder="请输入还款期数"
      ></el-input>
    </el-form-item>
    <el-form-item label="还款方式" prop="method">
      <el-input
        v-model="form.method"
        style="width: 300px"
        placeholder="请输入还款方式"
      ></el-input>
    </el-form-item>
    <el-form-item label="还款日期" prop="payDay">
      <el-input-number
        v-model="form.payDay"
        :min="1"
        :max="30"
        label="请选择还款日期"
        style="width: 300px"
      ></el-input-number>
    </el-form-item>
    <el-form-item label="首次还款日" prop="payFirstTime">
      <el-date-picker
        v-model="form.payFirstTime"
        type="date"
        placeholder="请选择首次还款日"
        style="width:300px"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="每期还款金额" prop="singleAmount">
      <el-input
        v-model="form.singleAmount"
        style="width: 300px"
        placeholder="请输入每期还款金额"
      ></el-input>
    </el-form-item>
    <el-form-item label="放款日期" prop="loanTime">
      <el-date-picker
        v-model="form.loanTime"
        type="date"
        placeholder="请选择放款日期"
        style="width:300px"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="是否有保证金" prop="isDeposit">
      <el-select
        v-model="form.isDeposit"
        placeholder="请选择"
        style="width:300px"
      >
        <el-option label="是" :value="1"> </el-option>
        <el-option label="否" :value="2"> </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="利率" prop="rate">
      <el-input
        v-model="form.rate"
        style="width: 300px"
        placeholder="请输入利率"
      >
        <template slot="append">%</template>
      </el-input>
    </el-form-item>
    <el-form-item label="冻结金额" prop="freezeAmount">
      <el-input
        v-model="form.freezeAmount"
        style="width: 300px"
        placeholder="请输入冻结金额"
      ></el-input>
    </el-form-item>
    <el-form-item label="借据方标识(外部订单号前缀以区分借据方)：" prop="identifier">
      <el-input 
      style="width: 300px" 
      placeholder="请输入资金方标识（四位字母或数字）" 
      @keyup.native="keyupHandle"
      v-model="form.identifier"
      maxlength="4"
      ></el-input>
    </el-form-item>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disabled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { saveFundingParty, getFundingPartyList } from "@/api/fundingParty";
import { queryHaiLiAgent } from "@/api/common";
import Reg from "@/js/reg";

export default {
  data() {
    const validLoanAmount = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };
    const validPayAmount = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };
    const validRate = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确利率"));
      } else {
        callback();
      }
    };
    const validFreezeAmount = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };

    return {
      form: {
        unitNo: "",
        name: "",
        loanAmount: "",
        payAmount: "",
        payIssue: "",
        method: "",
        payDay: "",
        payFirstTime: "",
        singleAmount: "",
        loanTime: "",
        isDeposit: "",
        rate: "",
        freezeAmount: "",
        identifier:""

      },
      rules: {
        unitNo: [
          { required: true, message: "请选择资金方", trigger: "change" }
        ],
        name: [{ required: true, message: "请输入借据名称", trigger: "blur" }],
        loanAmount: [{ validator: validLoanAmount, trigger: "blur" }],
        payAmount: [
          { required: true, message: "请输入还款总金额", trigger: "blur" },
          { validator: validPayAmount, trigger: "blur" }
        ],
        payIssue: [
          { required: true, message: "请输入还款期数", trigger: "blur" }
        ],
        method: [
          { required: true, message: "请输入还款方式", trigger: "blur" }
        ],
        payDay: [
          { required: true, message: "请选择还款日期", trigger: "blur" }
        ],
        payFirstTime: [
          { required: true, message: "请选择首次还款日", trigger: "blur" }
        ],
        singleAmount: [
          { required: true, message: "请选择每期还款金额", trigger: "blur" }
        ],
        rate: [{ validator: validRate, trigger: "blur" }],
        freezeAmount: [{ validator: validFreezeAmount, trigger: "blur" }]
      },
      disabled: false,
      agentList: [] //资金方数据
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      this.getFundingPartyList();
    }
    this.queryHaiLiAgent();
  },
  methods: {
    //input数字字母
    keyupHandle(){
      this.form.identifier = this.form.identifier.replace(/[^\w\.\/]/ig,'')
    },
    /*获取资金方列表 */
    async queryHaiLiAgent() {
      const params = {
        role: 0
      };
      const res = await queryHaiLiAgent(params);
      this.agentList = res.ldata;
    },
    //获取详情(列表接口传id)
    async getFundingPartyList() {
      const params = {
        number: this.$route.query.number,
        beginIndex: 1,
        pageSize: 10
      };
      const res = await getFundingPartyList(params);
      this.form = res.ldata[0];
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disabled = true;
          const params = {
            ...this.form
          };
          params.payFirstTime = String(Date.parse(this.form.payFirstTime));
          if (params.loanTime) {
            params.loanTime = String(Date.parse(this.form.loanTime));
          }

          if (this.$route.query.type == "edit") {
            params.number = this.$route.query.number;
          }
          try {
            const res = await saveFundingParty(params);
            this.disabled = false;
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/debt");
            }, 1000);
          } catch (error) {
            this.disabled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/debt');

    }
  }
};
</script>

<style scoped></style>
