import axios from "../js/ajax";
/** 借据列表 */
export const getFundingPartyList = params =>
  axios.get("/managementPlatform/operation/queryLoanReceipt", {
    params,
    loading: true
  });
/** 新增/编辑借据 */
export const saveFundingParty = params =>
  axios.post("/managementPlatform/operation/createOrEditLoanReceipt", params);
/** 删除借据 */
export const delFundingParty = params =>
  axios.post("/managementPlatform/operation/deleteLoanReceipt", params);
/** 分配电池 */
export const passTest = params =>
  axios.get("/managementPlatform/operation/createLoanReceiptDeviceRelation", {
    params
  });
/** 取消分配电池 */
export const saveProduct = params =>
  axios.get("/managementPlatform/operation/cancelLoanReceiptDeviceRelation", {
    params
  });
/** 我的（资金方）借据有关的设备 */
export const getWhiteList = params =>
  axios.get("/managementPlatform/operation/queryLoanReceiptDeviceRelation", {
    params
  });

/** 资金方列表 */
export const queryHaiLiUnit = params =>
  axios.get("/managementPlatform/operation/queryHaiLiUnitForFunder", {
    params,
    loading: true
  });

/** 新增资金方 */
export const createOrUpdateUnit = params =>
  axios.post("/managementPlatform/operation/createOrUpdateUnit", params);

/** 删除资金方 */
export const deleteHaiLiUnitMember = params =>
  axios.post("/managementPlatform/operation/deleteHaiLiUnitMember", params);

/** 编辑资金方-根据账户类型获取信息 */
export const queryUnitChannlAccount = params =>
  axios.get("/managementPlatform/operation/queryUnitChannlAccount", { params });

/** 查询与借据有关的设备和未分配的设备 */
export const getLoanList = params =>
  axios.get(
    "/managementPlatform/operation/queryLoanReceiptDeviceRelationForAdmin",
    {
      params,
      loading: true
    }
  );

/** 分配电池 */
export const createDeviceRelationList = params =>
  axios.post(
    "/managementPlatform/operation/createLoanReceiptDeviceRelation",
    params
  );

/** 获取资金方和借据树 */
export const queryUnitLoanReceiptTree = params =>
  axios.get("/managementPlatform/operation/queryUnitLoanReceiptTree", {
    params
  });

/** 删除资金方 */
export const newDelCashPerson = params => axios.get('/managementPlatform/operation/deleteHaiLiUnitMemberForFunder', {params})
